import React from "react"

import styles from "./index.module.scss"

import videoImage from "../../images/video.png"
import studentsImage from "../../images/students.png"
import coursePlaceholderImage from "../../images/ifff.png"

export default () => (
  <>
    <ul className={`${styles.container}`}>
      <li>
        <div className={`${styles.content}`}>
          <div className={`${styles.grid}`}>
            <div className={`${styles.firstColumn}`}>
              <h1>International Functional Fitness Federation</h1>

              <p>
                Online Educational Course Platform for Technical Officials,
                Coaches, Athletes, and Fans.
              </p>

              <div className={`${styles.iconsWrapper}`}>
                <div className={`${styles.icon}`}>
                  <img src={videoImage} alt="1 Course" />
                  <span>1 Course</span>
                </div>

                <div className={`${styles.icon}`}>
                  <img src={studentsImage} alt="60+ Students" />
                  <span>60+ Students</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul className={`${styles.tabs}`}>
      <li className={`${styles.tab}`}>
        <a href="/courses/toi" className={`${styles.tabLink}`}>
          Technical Official
        </a>
      </li>
    </ul>
    <ul className={`${styles.catalog}`}>
      <li>
        <div className={`${styles.catalogContainer}`}>
          <div>
            <div className={`${styles.headerNote}`}>
              <h2>Browse Technical Official courses</h2>
            </div>
          </div>
          <div className={`${styles.coursesWrapper}`}>
            <div className={`${styles.course}`}>
              <div className={`${styles.card}`}>
                <a href="/courses/toi" className={`${styles.cardHeader}`}>
                  <img
                    src={coursePlaceholderImage}
                    alt="Technical Official Introduction"
                  />
                </a>
                <div className={`${styles.cardBody}`}>
                  <a href="/courses/toi">
                    <h4>Technical Official Introductory</h4>
                    <p>
                      The Introductory Technical Officials Course is a basic
                      course designed to prepare a Technical Official (TO’s) for
                      an on field position at iF3 International Events.
                    </p>
                    <hr />
                  </a>
                  <div>
                    <a className={`${styles.cardButton}`} href="/courses/toi">
                      Buy
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </>
)
